import * as Funzioni from './function.js';

import 'jquery-ui/ui/widgets/datepicker.js';

$(document).ready(function(){
    Funzioni.init();


    if($(window).width() >= 768){
        Funzioni.carouselFeature();
    }

    $(window).on('scroll', function () {
        Funzioni.addClassScrollTop();
        Funzioni.navSelectAnchor();
    });

    Dropzone.autoDiscover = false;
});