export function init() {
    submitLogout();
    animateScroll();
    removeClassShow();
    initializeCarousel();
    login();
    // initializeDatepicker();
    sendMailContact();
    changeSaveFairImage();
    animateLink();
    sendEmailResetPassword();
    actionModalResetPassword();

    // using CommonJS modules
    var lozad = require('lozad')
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
}

function actionModalResetPassword() {
    $("#resetPw").on("show.bs.modal",function(){

        $('#areaPersonale').modal("hide");

    });
}

//Funzione che fa submit del Logout
function submitLogout() {
    $('.logout-link').on('click', function (e) {
        e.preventDefault();
        $('#logout-form').submit();
    });
}

//Funzione di animazione menù scroll
function animateScroll() {
    $('.nav-button').on('click',function(){
        $('.mobile-menu').removeClass('show');
        let $href = $(this).attr('href');
        $href = $href.substr($href.indexOf("#"));
        let $anchor = $($href).offset();
        $('html, body').animate({ scrollTop: $anchor.top });
        return false;
    });
}

//Funzione di animazione menù scroll
function animateLink() {
    if(window.location.href.indexOf('#') != -1){
        window.scrollTo(0,0);
        let val = window.location.href;
        let myString = val.substr(val.indexOf("#"));
        if ($(myString).offset() === undefined) return;
        let distance = $(myString).offset().top;
        $('html, body').animate({ scrollTop: distance });
    }
}

//Funzione che chiude il menù hamburger dopo aver cliccato
function removeClassShow() {
    $('.nav-button_area').on('click',function(){
        $('.mobile-menu').removeClass('show');
    });
}

//Funzione che inizializza il Carousel
function initializeCarousel() {
    let owl = $(".sliderCarousel");
    owl.owlCarousel({
        items:1,
        nav: false,
        dots: false,
        // navText: ["<img class='' src='./images/arrow_left.png'/>","<img class='' src='./images/arrow_right.png'/>"],
        URLhashListener:true,
        autoplayHoverPause:false,
        startPosition: 'URLHash',

        responsive : {
            // breakpoint from 0 up
            0 : {
                nav: true,
                navText: ["<img class='' src='./images/arrow_left.png'/>","<img class='' src='./images/arrow_right.png'/>"],

            },
            // breakpoint from 768 up
            992 : {
                nav: false,
            }
        }
    });
    owl.on('changed.owl.carousel', function(e) {
        var current = e.relatedTarget.current();
        $(".link_Carousel").removeClass("active");
        $('.link_Carousel[data-link="' + current + '"]').addClass('active');
    });

}

//Funzione che chiama l'api per il login
function login() {
    $("form[name=login]").on('submit', function (e) {
        e.preventDefault();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: $(this).attr('data-method'),
            url: $(this).attr('data-action'),
            data: $(this).serializeArray(),
            dataType: "json",
            success:function(success){
                let response = JSON.parse(JSON.stringify(success));
                location.href =  response.result;
            },
            error:function (error) {
                let response = JSON.parse(JSON.stringify(error)).responseJSON;
                let errors = response.errors;
                $("input[name=email]").next("p").remove();
                $("input[name=password]").next("p").remove();
                $.each(errors, function (field, message) {
                    $("input[name="+field+"]").after("<p class='error-message'>"+message+"</p>")
                })
            }
        });
    });

}

//Funzione che fa submit del Logout
function initializeDatepicker() {
    $( "#datepicker_inizio" ).datepicker(
        {
            dateFormat: 'dd-mm-yy'
        }
    );
    $( "#datepicker_fine" ).datepicker(
        {
            dateFormat: 'dd-mm-yy'
        }
    );
}

//Funzione che invia la mail del form di contatti
function sendMailContact(){
    $("#simple_contact").on("submit", function (e) {
        e.preventDefault();
        $.ajax({
            type: $(this).attr('data-method'),
            url: $(this).attr('data-action'),
            data: $(this).serializeArray(),
            dataType: "json",
            success:function(success){
                $("#title_contact").hide();
                $("#simple_contact").hide();
                $("#message_sent").show();

                // tracciamo analytics
                ga('send', {
                    hitType: 'event',
                    eventCategory: 'Form contatti',
                    eventAction: 'send',
                    eventLabel: 'Invio form contatto'
                });
            },
            error:function (error) {
                let response = JSON.parse(JSON.stringify(error)).responseJSON;
                $("#error__box").html(response.message).show();
            }
        });

    });
}

//Funzione che sistema posizione link Feature Carousel
export function carouselFeature() {
    //if (navigator.userAgent.search("Firefox") === -1) {
        let calculateWidthFeature = $('.content_slider').width();
        let calculatemarginArrow = $('.margin-arrow').width() + 10;
        let content = $('.content_link');

        content.css("width", calculateWidthFeature);
        content.css("left", calculatemarginArrow);
   /* }else{

        let calculateWidthFeature = $('.sliderCarousel__slide__text').width();
        let calculatemarginArrow = $('.margin-arrow').width() + 10;
        let slider = $('.content_slider');
        let content = $('.content_link');
        content.css("max-width", "1200px");
        slider.css("max-width", "1200px");
        content.css("width", slider);
    }*/


}

//Funzione che aggiunge classe (la classe viene usata per cambiare grafica al menù)
export function addClassScrollTop() {
    let scrollTop = $(window).scrollTop();

    if (scrollTop > 0){
        $('body').addClass('scroll-top');
        $('body').css('background-color','#6e9fb2');
      //  $('nav').addClass('fixed-top');
     //   $('header').addClass('sticky');
    }else{
        $('body').removeClass('scroll-top');
        $('body').css('background-color','#6e9fb2');
       // $('nav').removeClass('fixed-top');
       // $('header').removeClass('sticky');
    }

}

//Funzione che evidenzia il menù
export function navSelectAnchor() {
    let content = $('.general');
    if(content.length > 0) {
        let heightNav = content.outerHeight();
        // }
        let link = $('.navbar-nav ');
        let windowDistance = $(window).scrollTop();
        if (link.length > 0) {
            let easycontact = $('#easycontact').offset().top -1;
            let events = $('#events').offset().top -1;
            let download = $('#download').offset().top -1;
            let security = $('#security').offset().top -1;
            let about = $('#about').offset().top -1;
            let faq = $('#faq').offset().top -1;
            let contacts = $('#contacts').offset().top -1;

            let start = 0;

            if (windowDistance >= start && windowDistance <= easycontact) {
                $('.nav-link').removeClass('active');
            }

            else if (windowDistance >= easycontact && windowDistance <= events) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-fairsapp').addClass('active-link');
            }

            else if (windowDistance >= events && windowDistance <= download) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-eventi').addClass('active-link');
            }

            else if (windowDistance >= download && windowDistance <= security) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-download').addClass('active-link');
            }

            else if (windowDistance >= security && windowDistance <= about) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-sicurezza').addClass('active-link');
            }

            else if (windowDistance >= about && windowDistance <= faq) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-about').addClass('active-link');
            }

            else if (windowDistance >= faq && windowDistance <= contacts) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-faq').addClass('active-link');
            }

            else if (windowDistance > contacts) {
                $('.nav-link').removeClass('active-link');
                $('.nav-link.nav-contatti').addClass('active-link');
            }
        }
    }

}

// Cambiamo l'immagine caricata nell'upload fiera (solo admin)
export function changeSaveFairImage(){
    $('#fair-image-input').on('change', function () {
        if (this.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#fair-image').attr('src', e.target.result);
            };
            reader.readAsDataURL(this.files[0]);
        }
    });
}

// Inviamo la mail di reset password
export function sendEmailResetPassword(){
    $("form[name=forgotPassword]").on('submit', function (e) {
        e.preventDefault();

        let data = $(this).serializeArray();
        data.push({name: 'email', value: $("#emailForgotPw").val()});

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: $(this).attr('data-method'),
            url: $(this).attr('data-action'),
            data: data,
            dataType: "json",
            success:function(response){
                let color = "#000000";
                if (response['success']){
                    $("form[name=forgotPassword]")[0].reset();
                    color = "green";
                } else {
                    color = "red";
                }

                $("#response").html(response['message']).css('color',color).show();
            },
            error:function (error) {
                let response = JSON.parse(JSON.stringify(error)).responseJSON;
                let errors = response.errors;
                $.each(errors, function (field, message) {
                    $("#response").html(message).css('color',"red").show();
                });
            }
        });
    });

}
